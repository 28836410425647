<template>
  <div class="Stepbar">
    <div class="header-content">
      <p>
        <i class="el-icon-s-order"></i>
      </p>
      <p>{{stepName[active]}}</p>
      <div class="step" v-if="isEnterpriseOrder">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step :title="stepName[0]"></el-step>
          <el-step :title="stepName[1]"></el-step>
          <el-step :title="stepName[2]"></el-step>
        </el-steps>
      </div>
      <div class="step" v-else>
        <el-steps :active="active" finish-status="success" align-center>
          <el-step :title="stepName[0]"></el-step>
          <el-step :title="stepName[2]"></el-step>
          <el-step :title="stepName[3]"></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['active','enterprisevalue'],
  data() {
    return {
      stepName:['确认订单','企业信息','提交审核'],
      isEnterpriseOrder:false
    }
  },
  watch: {
    enterprisevalue(val){
      this.isEnterpriseOrder = val == 0 ? true : false
    }
  },
  created() {
    var currentCommodityDetail = JSON.parse(localStorage.getItem('currentCommodityDetail'))
    if(currentCommodityDetail){
      this.isEnterpriseOrder =  currentCommodityDetail.isEnterpriseOrder
    }
  },
  methods: {}
}
</script>
<style scoped>
.Stepbar {
  background-color: #fff;
  border-bottom: 2px solid #1e95d4;
  margin-bottom: 20px;
}
.Stepbar .header-content {
  width: 1225px;
  margin: 0 auto;
  height: 80px;
}
.Stepbar .header-content p {
  float: left;
  font-size: 28px;
  line-height: 80px;
  color: #424242;
  margin-right: 20px;
}
.Stepbar .header-content p i {
  font-size: 45px;
  color: #1e95d4;
  line-height: 80px;
}

.Stepbar .step {
  width: 50%;
  float: right;
  margin-top: 10px;
}
/* 头部CSS END */
</style>
